import firebase from "firebase";

// var firebaseConfig = {
//   apiKey: "AIzaSyBSaau-8RfNsPh89jyWyKZJ8YIIYrISjug", // Add API Key
//   databaseURL:"https://test-f7b7a.firebaseio.com" // Add databaseURL
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyBSaau-8RfNsPh89jyWyKZJ8YIIYrISjug",
//   authDomain: "test-f7b7a.firebaseapp.com",
//   databaseURL: "https://test-f7b7a.firebaseio.com",
//   projectId: "test-f7b7a",
//   storageBucket: "test-f7b7a.appspot.com",
//   messagingSenderId: "479178356413",
//   appId: "1:479178356413:web:330360149972efddec29d4"
// };


var firebaseConfig = {
  apiKey: "AIzaSyDt09ExMnRhWUGgkYbQ-uOxOH06IQ1nJWo", // Add API Key
  databaseURL:"https://consultancy-meet-default-rtdb.firebaseio.com" // Add databaseURL
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase;

var firepadRef = firebase.database().ref();

export const userName = prompt("What's your name?");
const urlparams = new URLSearchParams(window.location.search);
const roomId = urlparams.get("id");

if (roomId) {
  firepadRef = firepadRef.child(roomId);
} else {
  firepadRef = firepadRef.push();
  window.history.replaceState(null, "Meet", "?id=" + firepadRef.key);
}

export default firepadRef;
